import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../config.json';
import Navbar from './Navbar';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

export default function WebsiteChat() {
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState('');
  const [isNewChat, setIsNewChat] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [repos, setRepos] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState();
  const [chatVisible, setChatVisible] = useState(false);

  function changeNewMessage(e) {
    setNewMessage(e.target.value);
  }

  function dec2hex (dec) {
    return dec.toString(16).padStart(2, "0")
  }
  
  function generateId (len) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }

  function submitMessage() {
    setLoading(true);
    axios.post(config.BASE_URL + "/api/bitbucket/ask-question", {question: newMessage, chat_id: chatId, start_chat: isNewChat})
    .then(response => {
      if (response.data.status == "OK") {
        setMessages([...messages, {message: newMessage, author: "User"}, {message: response.data.data, author: "NovosCanais"}]);
        setNewMessage('');
        setLoading(false);
        setIsNewChat(false);
      }
      else {
        alert(response.data.error);
        setLoading(false);
        setIsNewChat(false);
      }
    })
    .catch(err => {
      alert(err.message);
      setLoading(false);
      setIsNewChat(false);
    });
  }

  
  function startChat() {
    setMessages([]);
    setChatId(generateId(6));
    setIsNewChat(true);
    setChatVisible(false);
  }

  function loadRepos() {
    axios.get(config.BASE_URL + "/api/bitbucket/get-repos")
    .then(response => {
      var repos = response.data.data;
      repos = repos.map((item) => { return {value: item, label: item} });
      setRepos(repos);
    })
    .catch(err => {
      console.log(err.message);
    });
  }

  function changeSelectedRepo(item) {
    setSelectedRepo(item);
  }

  function loadRepo(repo) {
    setLoading(true);
    axios.get(config.BASE_URL + "/api/bitbucket/load-repo", {params: {repo: repo, chat_id: chatId}})
    .then(response => {
      console.log(response.data.data);
      setChatVisible(true);
      setLoading(false);
    })
    .catch(err => {
      console.log(err.message);
    });
  }

  function updateRepo() {
    MySwal.fire({
      title: 'Tem a certeza?',
      text: "Tem a certeza que quer atualizar o repositório? Esta operação pode demorar algum tempo.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.get(config.BASE_URL + "/api/bitbucket/update-repo", {params: {repo: selectedRepo.value}})
        .then(response => {
          console.log(response.data.data);
          setLoading(false);
          startChat();
          MySwal.fire("The repo has been updated.");
        })
        .catch(err => {
          console.log(err.message);
        });
      }
    });
  }

  function addRepo() {
    MySwal.fire({
      title: 'Adicionar Repositório',
      text: "Digite o nome do repositório.",
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.get(config.BASE_URL + "/api/bitbucket/add-repo", {params: {repo: result.value}})
        .then(response => {
          console.log(response.data.data);
          setLoading(false);
          startChat();
          MySwal.fire("The repo has been added.");
        })
        .catch(err => {
          console.log(err.message);
        });
      }
    });
  }

  useEffect(() => {
    if (selectedRepo) {
      startChat();
    }
  }, [selectedRepo]);

  useEffect(() => {
    if (selectedRepo && isNewChat == true && chatId != '') {
      loadRepo(selectedRepo.value);
    }
  }, [chatId]);
  
  useEffect(() => {
    loadRepos();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container">
          <h1>Bitbucket Chat</h1>
          <div style={{textAlign: "right"}} className="mb-2">
              <button className="btn btn-primar mr-2" onClick={addRepo}>Adicionar Repositório</button>
          </div>
          {chatVisible && (
            <div style={{textAlign: "right"}} className="mb-2">
              <button className="btn btn-success mr-2" onClick={updateRepo}>Atualizar Repositório</button>
            </div>
          )}
          <h3>Selecione o repositório:</h3>
          <div className="mb-2" style={{width: "300px"}}>
            <Select options={repos} value={selectedRepo} onChange={changeSelectedRepo} />
          </div>
          {chatVisible && (
          <div className="chat-input">
              <div className="form-group">
                  <input type="text" className="form-control mb-2" value={newMessage} onChange={changeNewMessage} />
              </div>
              <div style={{textAlign: "right"}}>
                  <button className="btn btn-primary" onClick={submitMessage}>Enviar</button>
              </div>
          </div>
          )}
          {loading && (
            <div style={{textAlign: 'center'}}>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {chatVisible && (
          <div className="chat-messages">
              {messages.map((message, index) => {
                return (
                  <div className="chat-message" key={index}>
                      <div><b>{message.author}</b></div>
                      <div>{message.message}</div>
                  </div>
                )
              })}
          </div>
          )}
      </div>
    </>
  )
}
