import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../config.json';
import Navbar from './Navbar';

export default function WebsiteChat() {
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState('');
  const [isNewChat, setIsNewChat] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);

  function changeNewMessage(e) {
    setNewMessage(e.target.value);
  }

  function dec2hex (dec) {
    return dec.toString(16).padStart(2, "0")
  }
  
  function generateId (len) {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
  }

  function submitMessage() {
    setLoading(true);
    axios.post(config.BASE_URL + "/api/website/ask-question", {question: newMessage, chat_id: chatId, start_chat: isNewChat})
    .then(response => {
      if (response.data.status == "OK") {
        setMessages([...messages, {message: newMessage, author: "User"}, {message: response.data.data, author: "NovosCanais"}]);
        setNewMessage('');
        setLoading(false);
        setIsNewChat(false);
      }
      else {
        alert(response.data.error);
        setLoading(false);
        setIsNewChat(false);
      }
    })
    .catch(err => {
      alert(err.message);
      setLoading(false);
      setIsNewChat(false);
    });
  }

  
  function startChat() {
    setMessages([]);
    setChatId(generateId(6));
    setIsNewChat(true);
  }
  

  useEffect(() => {
    startChat();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container">
          <h1>Website Chat</h1>
          <div style={{textAlign: "right"}}>
            <button className="btn btn-primary" onClick={startChat}>Start New Chat</button>
          </div>
          <div className="chat-input">
              <div className="form-group">
                  <input type="text" className="form-control mb-2" value={newMessage} onChange={changeNewMessage} />
              </div>
              <div style={{textAlign: "right"}}>
                  <button className="btn btn-primary" onClick={submitMessage}>Enviar</button>
              </div>
          </div>
          {loading && (
            <div style={{textAlign: 'center'}}>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div className="chat-messages">
              {messages.map((message, index) => {
                return (
                  <div className="chat-message" key={index}>
                      <div><b>{message.author}</b></div>
                      <div>{message.message}</div>
                  </div>
                )
              })}
          </div>
      </div>
    </>
  )
}
