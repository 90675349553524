import React, {useEffect, useState} from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import config from '../config';
import ReactPaginate from 'react-paginate';

export default function Log() {
  const [log, setLog] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const messagesPerPage = 10;

  function loadLog() {
    setLog([]);
    axios.get(config.BASE_URL + '/api/get-log', {
      params: {
        offset: page * messagesPerPage, 
        limit: messagesPerPage,
      }
    })
    .then(function(response) {
      console.log(response.data);
      if (response.data.status == "OK") {
        setLog(response.data.data.log);
        setTotalPages(Math.ceil(response.data.data.count / messagesPerPage));
      }
      else {
        alert(response.data.error);
      }
    })
    .catch(function(err) {
      alert(err.message);
    });
  }

  function changePage({ selected }) {
    setPage(selected);
  }

  useEffect(() => {
    loadLog();
  }, [page]);

  useEffect(() => {
    setPage(0);
    loadLog();
  }, []);

  return (
    <>
        <Navbar />
        <div className="container">
          <h1>Log</h1>

          {log.map((message, index) => (
            <div key={index} className="chat-message">
              <div><b>Chat ID: </b>{message.chat_id}</div>
              <div><b>{message.author}</b></div>
              <div>{message.message}</div>
            </div>
          ))}

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPages}
            onPageChange={changePage}
            containerClassName={"navigationButtons"}
            previousLinkClassName={"previousButton"}
            nextLinkClassName={"nextButton"}
            disabledClassName={"navigationDisabled"}
            activeClassName={"navigationActive"}
          />


        </div>
    </>
  )
}
