import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WebsiteChat from './components/WebsiteChat';
import Log from './components/Log';
import Login from './components/Login';
import BitbucketChat from './components/BitbucketChat';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/website-chat" element={<WebsiteChat />} />
        <Route path="/bitbucket-chat" element={<BitbucketChat />} />
        <Route path="/log" element={<Log />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
